@import '../../assets/variables/variables.scss';
.users{
    @include custom-scrollbar();
    display: flex;
    flex-direction: column  ;
    align-items: center;
    width: 100%;
    height: 100%;   
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-gutter: stable both-edges;
    
    &__searchbar{
        margin: 2rem;
        margin-bottom: 1rem;
    }
    &__tableTop{
        overflow: hidden ;
        width: var(--pages-content-width);
        color: var(--color-brown-dark);
        font-weight: 600;
        height: 45px;
        min-height: 45px;
        padding-inline: 20px;
        display: flex;
        align-items: center;
        text-align: left;
        border-bottom: 2px solid var(--color-brown-medium);
        margin-bottom: 1rem;

        > * {
            overflow:hidden; 
            white-space:nowrap; 
            text-overflow: ellipsis;
            font-weight: 700;
        }
        :nth-child(1){
            width: 25rem;
        }
        :nth-child(2){
            width: 35rem;
            margin-right: auto;
        }
        :nth-child(3){
            width: 14rem;
        }
        :nth-child(4){
            width: 9rem;
        }
    }
    
    &__userList{
        //max-width: 1200px;
       //width: calc(100% - 4rem);
        width: var(--pages-content-width);
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 2rem;
        
        &__buttons{
            position: relative;
            align-self: flex-end;
            cursor: pointer;
            display: flex;
            gap: 1rem;
         
            opacity: 1;

            &__value{
                font-weight: 800;
                font-size: 1.3rem;
                color: var(--color-brown-dark);
            }
            &__disactive{
                pointer-events: none;
                opacity: 0.3;
            }
        }
    }

    
}

@media (max-width: 768px) {
    .users {
        &__tableTop {
            :nth-child(3),
            :nth-child(4){
               display: none;
            }
        }
    }
}