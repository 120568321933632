.header {
  position: fixed;
  background-color: var(--color-white);
  height: var(--header-height);
  width: 100vw;
  border-bottom: 1.5px solid var(--color-gray-light);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 800;
  color: var(--color-brown-dark);

  


  &__logo {
   
    margin-left: 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    

    h1 {
      font-size: 32px;
    }

    &__menu{
      display: none;
    }
  }
  &__notificationAlert {

    background-color: rgb(245, 36, 36);
    //transform: translateY(8px);
    border-radius: 100px;
    height: 10px;
    width: 10px;
  }
  &__user {
    
    position: relative;
    margin-right: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;

    &__notification {
        cursor: pointer;
        transform: translateY(-5px);
      &__alert {

        background-color: rgb(245, 36, 36);
        transform: translate(13px,9px);
        border-radius: 100px;
        height: 10px;
        width: 10px;
      }
    }

    img {
      border-radius: 100px;
      height: 40px;
      width: 40px;
    }
  }
}

@media (max-width: 768px) {
  .header {
    &__logo {
      h1 {
        display: none;
      }
      &__icon{
        display: none;
      }
      &__menu {
        display: block;
        cursor: pointer;
      }
    }
    &__user {
      margin-right: 1rem;
      gap: 0.5rem;
      img {
        height: 30px;
        width: 30px;
      }
    }
  }
}
