@import '../../assets/variables/variables.scss';
.toilets {
  &__overlay {
    pointer-events: none;
    width: 100%;
    position: fixed;
    z-index: 222;

    &__searchbar {
      z-index: 2;
      display: flex;
      position: fixed;
      margin-top: 1rem;
      margin-left: 1rem;
    }
    &__searchBtn {
      display: none;
      transform: scale(0);
      position: fixed;
      margin-top: 1rem;
      margin-left: 1rem;
    }
    &__filterList {
      @include custom-scrollbar();
      //z-index: 4;
      position: fixed;
      pointer-events: all;
      height: calc(100dvh - var(--header-height));
      background-color: var(--color-brown-light);
      width: var(--map-info-panel-width);
      align-items: center;
      box-sizing: border-box;
      //width: var(--sidebar-width);
      display: none;
      
      box-shadow: 4px 0 6px 0px rgba(0, 0, 0, 0.06);
      flex-direction: column;
      padding-top: 5rem;
      padding-inline: 1rem;
      padding-bottom: 2rem;
      gap: 1rem;
      overflow-y: auto;
      scrollbar-gutter: stable both-edges;
     
    }
   
    
   
    &__toiletDetail {
      z-index: 9;
      //position: fixed;
      pointer-events: all;
      height: calc(100dvh - var(--header-height));
      background-color: var(--color-brown-light);
      width: var(--map-info-panel-width);
      align-items: center;
      display: flex;
     
    }
   

    &__searchAreaBtn {
      position: absolute;
      top: 1rem;
      right: 5rem;
      pointer-events: all;
    }
    &__loading {
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-white);
      height: 36px;
      width: 70px;
      position: absolute;
      top: 1rem;
    
      right: calc(50% - 35px);
      @media screen and (max-width: 1024px) {
        right: 6rem;
      }
      @media screen and (max-width: 768px) {
        right:  calc(50% - 35px);
      }
    }
  }
  &__map {
    z-index: 0;
    //background-color: rgba(0, 255, 17, 0.5);
    color: #fff;
    font-family: monospace;
    position: absolute;
    right: 0;
    height: calc(100dvh - var(--header-height));
    width: calc(100vw - var(--sidebar-width));
    bottom: 0;
  }
}

.marker {

  pointer-events: all;
  background-image: url("/assets/img/defaultMarker.svg");
  background-size: cover;
  width: 33px;
  height: 44px;
  cursor: pointer;
  transition: width 0.17s, height 0.17s, margin-top 0.17s;
  //filter: invert(1);
}

.marker:hover {
  filter: brightness(0.98);
  width: 40px;
  height: 53px;
  margin-top: -3.5px;
  transition: width 0.1s, height 0.1s, margin-top 0.1s;
}
.marker:active {
  width: 30px;
  height: 41px;
  margin-top: 1.3px;
  transition: width 0.05s, height 0.05s, margin-top 0.05s;
}

.marker__danger {
  background-image: url("/assets/img/dangerMarker.svg");
}
.marker__selected {
  //background-image: url('/assets/img/darkMarker.svg');
}

@media screen and (max-width: 576px) {
  .toilets {
    &__overlay {
      &__searchbar {
        display: none;
        z-index: 2;
        
        overflow: hidden;
        margin-left: 5rem;
        input{
          padding-right: 0rem !important;
          background-color: #fff;
          background: none;
          width: calc(100vw - 6rem) !important;
        }
      }
      &__searchBtn {
        display: flex;
        transform: scale(1);
      }
      &__filterList {
        width: 100%;
      }
      &__toiletDetail {
        width: 100%;
      }
    }
    &__map {
      width: 100%;
    }
  }
}
