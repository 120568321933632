.searchbar{
    
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    &:nth-child(0){
        width: 100px;
        color: red;
        background-color: red;
    }
    background-color: var(--color-white);
    border-radius: 100px;
    
    input{
        
        border-radius: 100px;
        height: 48px;
        width: 310px;
        padding: 20px 20px;
        box-sizing: border-box;
        outline: none;

        border:  #ffffff solid 2px;
        font-size: 17px;
        font-weight: 800;
        font-family: "Manrope",sans-serif ,monospace;
        color: var(--color-brown-dark);
        // box-shadow: 0 2px 4px rgba(79, 52,33, 0.2);

        background: url("../../assets/img/search.svg") no-repeat right;
        background-size: 24px; 
        background-position: 266px 10px; 

        
    }
    input::placeholder {
        font-weight: bold;
        color: var(--color-brown-medium);
    }
    input:-webkit-autofill,
    input:-webkit-autofill:focus{
        
        -webkit-box-shadow: 0 0 0 30px #f8f3f3 inset !important;
        -moz-box-shadow: 0 0 0 30px #f8f3f3 inset !important;
        -o-box-shadow: 0 0 0 30px #f8f3f3 inset !important;
        box-shadow: 0 0 0 30px #fbfafa inset !important;
        -webkit-text-fill-color: var(--color-brown-dark) !important;
        -o-text-fill-color: var(--color-brown-dark) !important;
        -moz-text-fill-color: var(--color-brown-dark) !important;
    }
    input:focus-visible {
        outline-color: var(--color-brown-dark);
    }
    textarea:focus, input:focus{
        outline: none;
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
        box-shadow: none;
    }
    
    svg{
        cursor: pointer;
        position: relative;
        
    
        path{
            stroke: var(--color-brown-dark);
        }
        font-size: 90px;
    }
    svg:hover{
        
        opacity: 0.85;
    }
    svg:active{

        opacity:   1;
    }

}



