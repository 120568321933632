
.card{
    background-color: white;
    height: fit-content;

   // width: 100%;
    box-sizing: border-box;
    border-radius: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding: 10px;
    gap: 10px;
    cursor: pointer;

    &__img{
        aspect-ratio: 1 / 1; 
        height: 90px;
        min-height: 90px;
        object-fit: cover;
        border-radius: 6px;
    }

    &__info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 90px;
        color: var(--color-brown-dark);
        width: 100%;

        &__top{
            
        }

        &__tags{
            display: flex;
            flex-direction: row;
            gap: 0.2rem;
        }
        h1{
            
            font-weight: 800;
            font-size: 1rem;
            max-width: 190px;
            line-height: 20px;
            //overflow:hidden; 
            white-space:nowrap; 
            text-overflow: ellipsis;
            text-wrap: wrap;
        }
        h3{
            margin-top: 0.3rem;
            font-weight: 800;
            font-size:0.8rem;
            color: var(--color-brown-medium);
            max-width: 190px;
            overflow:hidden; 
            white-space:nowrap; 
            text-overflow: ellipsis;
        }
    }

    
}

.card:hover{
    background-color: #fbf8f7;
}
