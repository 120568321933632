.toilet {
  cursor: pointer;
  background-color: var(--color-background);
  border-radius: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 50px;
  margin-inline: 1rem;

  p {
    text-decoration: none !important;
    overflow: hidden;
    line-height: 2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  :nth-child(1) {
    width: 3rem;
    color: var(--color-brown-medium);
    font-size: 1.4rem;
    font-weight: 700;
    margin-left: 1rem;
  }
  :nth-child(2) {
    width: 16rem;
    color: var(--color-brown-dark);
    font-size: 1.1rem;
    font-weight: 700;
  }
  :nth-child(3) {
    width: 30rem;
    color: var(--color-brown-medium);
    font-size: 0.9rem;
    font-weight: 700;
  }
  :nth-child(4) {
    width: 10rem;
    color: var(--color-brown-dark);
  }
  :nth-child(5) {
    margin-left: auto;
    color: var(--color-brown-dark);
    font-size: 1.4rem;
    width: 2rem;
    margin-right: 1rem;
    text-align: right;
  }

  //border-top: 2px solid var(--color-gray-light);
}

.toilet:hover {
  background-color: #f7f4f3;
}

@media (max-width: 768px) {
  .toilet {
    margin-inline: 0rem;
    :nth-child(3) {
      display: none;
      width: 0rem;
    }
    :nth-child(4) {
      display: none;
      width: 0rem;
    }
  }
}
