.mapbox_popup{
    color: var(--color-brown-dark);
}

.mapboxgl-ctrl-geolocate-active{
    background-color: blue;
}
.mapboxgl-ctrl-group{
    border-radius: 100px;
    overflow: hidden;
}
.mapboxgl-ctrl-group button:only-child {
    border-radius: 100px;
    overflow: hidden;
}

.mapboxgl-ctrl-group button{
    width: 48px;
    height: 48px;


    /* background-color: red; */

}
.MuiChartsLegend-mark{
    width: 20px; 
    height: 20px;
    rx: 50%;
    ry: 50%;
}

.mapboxgl-ctrl-group > button + button {
     border-top: 0px solid #ddd; 
}
.mapboxgl-ctrl-group:not(:empty) {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, .1);
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
     margin: 1rem 1rem 0 0; 
    float: right;
}
.mapboxgl-ctrl-icon{
    filter: brightness(0.6) sepia(0.8) hue-rotate(-10deg) saturate(2);
    padding: 0px;
    width: 24px;
    color: var(--color-brown-dark);
    height: 33px !important;
    border-radius: 100px;

}
mapboxgl-ctrl button .mapboxgl-ctrl-icon {
    background-position: 50%;
    background-repeat: no-repeat;
    display: block;
    height: 100%;
    width: 0px; 
}
