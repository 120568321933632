.login {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: var(--color-brown-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__form {
    flex-direction: column;
    background-color: var(--color-white);
    padding: 3rem;
    margin-bottom: 2rem;
    border-radius: 9px;
    width: fit-content;
    height: fit-content;

    &__info {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 1rem;
      &__logo {
        width: 100px;
        height: 100px;
        margin: 0 auto;
        margin-top: -30px;
      }
    }

    &__group {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      &__bar {
        margin-bottom: 1rem;
      }
    }

    &__error {
      position: absolute;
      width: 100%;
      max-width: 340px;
      margin-top: 10px;
      color: red;
      text-align: center;
      font-weight: 700;
    }
  }

  label {
    font-size: 16px;
    font-weight: 800;
    font-family: "Manrope", sans-serif, monospace;
    color: var(--color-brown-dark);
    margin-bottom: 0.5rem;
  }

  h1 {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    font-family: "Manrope", sans-serif, monospace;
    color: var(--color-brown-dark);
  }
  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    font-family: "Manrope", sans-serif, monospace;
    color: var(--color-brown-dark);
  }

  input {
    background-color: var(--color-brown-light);
    border-radius: 100px;
    height: 48px;
    min-height: 48px;
    width: 310px;
    padding: 20px 20px;
    box-sizing: border-box;
    outline: none;
    border: #ffffff solid 2px;
    font-size: 16px;
    font-weight: 800;
    font-family: "Manrope", sans-serif, monospace;
    color: var(--color-brown-dark);
    padding-right: 60px;
  }
  input::placeholder {
    font-weight: bold;
    color: var(--color-brown-medium);
  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px var(--color-brown-light) inset !important;
    -moz-box-shadow: 0 0 0 30px var(--color-brown-light) inset !important;
    -o-box-shadow: 0 0 0 30px var(--color-brown-light) inset !important;
    box-shadow: 0 0 0 30px var(--color-brown-light) inset !important;
    -webkit-text-fill-color: var(--color-brown-dark) !important;
    -o-text-fill-color: var(--color-brown-dark) !important;
    -moz-text-fill-color: var(--color-brown-dark) !important;
  }
  input:focus-visible {
    outline-color: var(--color-brown-dark);
  }
  textarea:focus,
  input:focus {
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    box-shadow: none;
  }

  svg {
    cursor: pointer;
    position: absolute;
    transform: translate(263px, 36px);
    path {
      stroke: var(--color-brown-dark);
    }
    font-size: 90px;
  }
  svg:hover {
    transform: translate(263px, 36px) scale(1.06);
    opacity: 0.85;
  }
  svg:active {
    transform: translate(263px, 36px) scale(0.95);
    opacity: 1;
  }

  &__errorInput {
    border: 2px solid red !important;
  }
}

.message {
  position: absolute;
  text-align: center;
  font-weight: 700;
  bottom: 1rem;
  color: var(--color-brown-medium);
  max-width: var(--pages-content-width);
}

@media (max-width: 768px) {
  .login {
    background-color: white;
    &__form {
      background-color: none;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      
      height: 100%;
      width: 100%;
      margin: 0rem;
      padding: 0;
      padding-bottom: 9rem;
      border-radius: 0;
      max-width: 310px;


      &__error {
        position: relative;
        width: fit-content;
        margin-top: 8px;
        color: red;
        text-align: center;
        font-weight: 700;
        width: 100%;
        max-width: 310px;
      }
    }
  }
}
