.modal{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    top: 0;
    left: 0;
    width: 100vw; 
    height: 100vh; 
    z-index: 9998;
    

      
    &__overlay{
        position: fixed;

        top: 0;
        left: 0;
        width: 100vw; 
        height: 100vh; 

   
        background-color: rgba(0, 0, 0, 0.5);
       
    }

    &__content{
        color: var(--color-brown-dark);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        width: 10vw;
        height: 10vh;
        background-color: white;
        border-radius: 12px;
        padding: 1rem;
        z-index: 9999;

        padding: 2rem;
         
        
        &>h1{
            margin-bottom: 0.4rem;
        }
        &>p{
            font-size: 0.95rem;
            margin-bottom: 0.4rem;
        }
    }
    
}