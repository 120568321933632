@import '../../assets/variables/variables.scss';
.sidebar{
    @include custom-scrollbar();
    position: fixed;
  
    background-color: var(--color-white);
    height: calc(100vh - var(--header-height));
    bottom: 0; 
    width: var(--sidebar-width);
    
    overflow-y: auto;
    
    &__closeBtn{
        display: none;
        pointer-events: none;
    }

    &__list{
        height: calc(100vh - var(--header-height) - 40px );
        display: flex;
        flex-direction: column;
        flex: 1;
        
        margin-inline: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        gap: 1rem;
    }
}

@media (max-width: 768px) {
    .sidebar {
        z-index: 9;
        height:  100%;
        border-right: 2px solid var(--color-gray-light);
        display: flex;
        position: absolute;
        width: 240px;
        bottom: 0;
        left: 0;
        transform: translateX(-242px);
        transition: all 0.3s ease-in-out;
        &__list{
            padding-top: 2rem;
            padding-inline: 1rem;
            box-sizing: border-box;
        }
        &__closeBtn{
            display: flex;
            pointer-events: all;
            position: absolute;
            top: -1rem;
            right: 1rem;
        }
    }
    .sidebar_active {
        transform: translateX(0);
        transition: all 0.4s ease-in-out;
    }
    

    
    .sidebar__list {
        margin-inline: 0;
    }
}