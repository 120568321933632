.reset{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: var(--color-brown-light);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__form{
        flex-direction: column;
        background-color: var(--color-white);
        padding: 3rem 3rem 1.5rem 3rem;
   
        margin-bottom: 2rem;
        border-radius: 9px;
        width: fit-content;
        height: fit-content;
        overflow: hidden;
        display: flex;
        justify-content: center;
      


        &__info{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: fit-content;
            margin-bottom: 1rem;

            &__logo{
                width: 100px;
                height: 100px;
                margin: 0 auto;
                margin-top: -30px;
            }
        }

        &__group{
            display: flex;
            flex-direction: column;
        }

        &__error{
            position: relative;
            width: 100%;

            margin-top: 8px;
            color: red;
            text-align: center;
            font-weight: 700;
            max-width: 310px;
        }
        
        &__errorInactive{
            position: relative;
            width: 100%;

            margin-top: 8px;
            color: rgba(255, 0, 0, 0);
            text-align: center;
            font-weight: 700;
        }
    }

    &__success{
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items:  center;
        text-align: center;
        color: var(--color-brown-dark);
        font-size: 18px;
        font-weight: 800; 
        max-width: 300px;  
        h2{
            color: var(--color-green);
            font-size: 22px;
            font-weight: 800;
            margin-bottom: 0.5rem;
        }
        p{
            margin-bottom: 1rem;
        }

    }

    label{
        font-size: 16px;
        font-weight: 800;
        font-family: "Manrope",sans-serif ,monospace;
        color: var(--color-brown-dark);
    }

    h1{
        text-align: center;
        font-size: 40px;
        font-weight: 700;
        font-family: "Manrope",sans-serif ,monospace;
        color: var(--color-brown-dark);
    }
    h3{
        text-align: center;
        font-size: 18px;
        font-weight: 800;
        font-family: "Manrope",sans-serif ,monospace;
        color: var(--color-brown-dark);
        
    }


    input{
        margin-top: 0.2rem;
        margin-bottom: 1rem;
        background-color: var(--color-brown-light);
        border-radius: 100px;
        height: 48px;
        min-height: 48px;
        width: 310px;
        padding: 20px 20px;
        box-sizing: border-box;
        outline: none;
        border:  #ffffff solid 2px;
        font-size: 16px;
        font-weight: 800;
        font-family: "Manrope",sans-serif ,monospace;
        color: var(--color-brown-dark);
    }
    input::placeholder {
        font-weight: bold;
        color: var(--color-brown-medium);
    }
    input:-webkit-autofill,
    input:-webkit-autofill:focus{
        
        -webkit-box-shadow: 0 0 0 30px var(--color-brown-light) inset !important;
        -moz-box-shadow: 0 0 0 30px var(--color-brown-light) inset !important;
        -o-box-shadow: 0 0 0 30px var(--color-brown-light) inset !important;
        box-shadow: 0 0 0 30px var(--color-brown-light) inset !important;
        -webkit-text-fill-color: var(--color-brown-dark) !important;
        -o-text-fill-color: var(--color-brown-dark) !important;
        -moz-text-fill-color: var(--color-brown-dark) !important;
    }
    input:focus-visible {
        outline-color: var(--color-brown-dark);
    }
    textarea:focus, input:focus{
        outline: none;
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
        box-shadow: none;
    }

    svg{
        cursor: pointer;
        position: absolute;
        transform: translate(263px,31px);
        path{
            stroke: var(--color-brown-dark);
        }
        font-size: 90px;
    }
    svg:hover{
        transform: translate(263px, 31px) scale(1.06);
        opacity: 0.85;
    }
    svg:active{
        transform: translate(263px, 31px) scale(0.95);
        opacity:   1;
    }

}
@media (max-width: 768px) { 

    .reset{
        background-color: white;
        &__form{
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            
            height: 100%;
            width: 100%;
            margin: 0rem;
            padding: 0;
            padding-bottom: 9rem;
            border-radius: 0;
            max-width: 310px;
        
        }
    }
}

