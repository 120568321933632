@mixin small-dark-text {
    font-size: 1rem;
    font-weight: 800;
    color: var(--color-brown-dark);
    line-height: 1.4rem;
}

@mixin custom-scrollbar($thumb-color: var(--color-brown-medium), $width: 5px, $radius: 2px) {
    &::-webkit-scrollbar {
      width: $width;
    }
    &::-webkit-scrollbar-track {
      border-radius: $radius;
    }
    &::-webkit-scrollbar-thumb {
      background: $thumb-color;
      border-radius: $radius;
    }
  }