@import '../../assets/variables/variables.scss';
.dashboard {
  @include custom-scrollbar();
  padding-inline: calc((100% - var(--pages-content-width)) / 2);
  overflow-y: scroll;
  height: 100%;
  overflow-y: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow-x: hidden;
  $minColWidth: 14rem;
  $gridGap: 2rem;

  &__stats {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax($minColWidth, 1fr));
    grid-gap: 2rem;

    min-height: 190px;
    & + .grid {
      margin-top: $gridGap;
    }
    & > * {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border-radius: 12px;
      background-color: var(--color-white);
      padding: 1rem;
    }

    h1 {
      color: var(--color-brown-dark);
      font-size: 1.3rem;
      margin: 0.2rem;

      margin-bottom: 0.5rem;
      font-weight: 700;
    }
  }
  &__topList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 2rem;

    &__header {
      display: flex;
      flex-direction: row;
      margin-inline: 1rem;
      padding-bottom: 0.5rem;
      border-bottom: 2px solid var(--color-brown-medium);
      p {
        color: var(--color-brown-dark);
        font-weight: 700;
      }
      :nth-child(1) {
        width: 3rem;
        margin-left: 1rem;
      }
      :nth-child(2) {
        width: 16rem;
      }
      :nth-child(3) {
        width: 30rem;
      }
      :nth-child(4) {
        width: 10rem;
      }
      :nth-child(5) {
        margin-left: auto;
        width: 2rem;
        margin-right: 1rem;
      }
    }
  }

  &__card {
    display: flex;

    align-items: center;
    flex-direction: column;

    gap: 0rem;
    width: clamp(200px, 30%, 400px);
    padding: 1rem;
    border-radius: 9px;
    background-color: var(--color-white);
    color: var(--color-brown-dark);

    :nth-child(1) {
      font-size: clamp(1rem, 1.1rem, 2rem);
      font-weight: 800;
    }
    :nth-child(2) {
      font-size: 4rem;
      font-weight: 600;
    }
  }

  &__chart {
    width: var(--pages-content-width);
    margin-bottom: 2rem;
    padding: 2rem;
    border-radius: 9px;
    background-color: var(--color-white);

    &__component {
      padding-right: 2rem;
      padding-bottom: 1rem;
    }
  } 
}

@media (max-width: 768px) {
  .dashboard {
    &__stats {
      gap: 0rem;
    }
    &__grid {
      grid-gap: 0rem;
    }
    &__topList {
      &__header {
        margin-inline: 0rem;
        :nth-child(3) {
          display: none;
          width: 0rem;
        }
        :nth-child(4) {
          display: none;
          width: 0rem;
        }
      }
    }
    &__chart {
      height: 20px;
    }
  }
}
