@mixin defaultSidebarBtn(
  $color-text: var(--color-brown-dark),
  $bg: var(--color-brown-light)
) {
  background-color: $bg;
  cursor: pointer;
  text-decoration: none;
  color: $color-text;
  min-height: 48px;
  border-radius: 100px;
  -webkit-user-select: none;
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none;
  &__item {
    min-height: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-inline: 1rem;
    gap: 0.7rem;
    p {
      font-size: 20px;
      font-weight: 800;
    }
  }

  path {
    stroke: $color-text;
  }
}

.element {
  @include defaultSidebarBtn(
    $color-text: var(--color-brown-dark),
    $bg: var(--color-brown-light)
  );
}
.element:hover {
  @include defaultSidebarBtn(
    $color-text: var(--color-brown-dark),
    $bg: var(--color-brown-light)
  );
}

.elementActive {
  @include defaultSidebarBtn($color-text: var(--color-brown-medium), $bg: transparent);
}
.elementActive:hover {
  @include defaultSidebarBtn($color-text: #998070, $bg: #fbf8f7);
}

.elementLogout {
  margin-top: auto;
  @include defaultSidebarBtn($color-text: var(--color-brown-medium), $bg: transparent);
}
.elementLogout:hover {
  @include defaultSidebarBtn($color-text: #998070, $bg: #fbf8f7);
}
.elementLogout:active {
  @include defaultSidebarBtn(
    $color-text: var(--color-brown-dark),
    $bg: var(--color-brown-light)
  );
}

.svgLoader {
  width: 28px;
  background-color: #998070;
}
