.user{
    width: 100%; 
    
    
    &__info{
        
        cursor: pointer;
        overflow: hidden ;
        background-color: var(--color-white);
        color: var(--color-brown-dark);
        font-weight: 600;
        border-radius: 9px;
        height: 55px;
        min-height: 55px;
        padding-inline: 20px;
        display: flex;
        align-items: center;
        text-align: left;
        
        p{
            text-decoration: none !important;
            overflow:hidden; 
            line-height: 2rem;
            white-space:nowrap; 
            text-overflow: ellipsis;
   
            font-weight: 700;
   
        }

        :nth-child(1){
            width: 25rem;
            font-size: 1rem;
            font-weight: 700;
        }
        :nth-child(2){
            width: 35rem;
            margin-right: auto;
            color: var(--color-brown-medium);
            font-weight: 700;
        }
        :nth-child(3){
            width: 14rem;
            font-size: 1rem;
            
        }
        :nth-child(4){
            width: 9rem;
            font-size: 1rem;
        }
        
    }
    &__info:hover{
        background-color: #fbf8f7;
    }

   
   
    
}
@media (max-width: 768px) {
    .user {
        &__info {

            :nth-child(3),
            :nth-child(4) {
                display: none;
                width: 100%;
                margin-right: 0;
            }
        }
    }
}