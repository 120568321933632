@import '../../assets/variables/variables.scss';
.container {
  @include custom-scrollbar();
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.userDetail {
  height: 100%;
  width: var(--pages-content-width);
  box-sizing: border-box;
  padding-block: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
  &__container {
    width: 100%;
    button {
      margin-left: auto;
      max-width: 290px;
      margin-bottom: 2rem;
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-self: center;
    flex-wrap: wrap;
    margin-inline: 1rem;
    margin-bottom: 1.5rem;
    gap: 1.5rem;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: end;
      padding-bottom: 0.5rem;
      min-width: 260px;
      gap: 0.3rem;
      color: var(--color-brown-dark);
    }

    h1 {
      font-size: 2rem;
      margin-bottom: 0.2rem;
      font-weight: 800;
    }

    &__img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }

  &__toiletList {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: left;
    flex-wrap: wrap;
    margin-inline: 1rem ;
    row-gap: 1rem;
    column-gap: 0.5rem;
    margin-bottom: 2rem;

    &__title {
      margin-inline: 1rem;
      padding-bottom: 0.5rem;
      margin-bottom: 1rem;
      color: var(--color-brown-dark);
      font-weight: 800;
      border-bottom: 2px solid var(--color-brown-medium);
    }

    &__message {
      font-weight: 800;
      color: var(--color-brown-medium);
    }

    & > div {
      max-width: 340px;
    }
  }
}

.infoBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1.3rem;
  gap: 0.3rem;

  &__title {
    font-weight: 700;
    color: var(--color-brown-medium);
  }

  &__value {
    color: var(--color-brown-dark);
    font-weight: 800;
  }
}

@media (max-width: 768px) {
  .userDetail {
    padding-block: 1rem;

    &__info {
      & > div {
        min-width: auto;
      }
    }
  }
}
