@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --color-white: white;
    --color-red: #CE4C4C;
    --color-green: #56B247;
    --color-background : #F0ECE9;
    --color-brown-light : #F7F1EE;
    --color-brown-soft : #CDC4BD;
    --color-brown-medium : #A79990;
    
    --color-brown-dark: #4F3421;
    --color-gray-light: #D9D9D9;

    --header-height: 80px;
    --sidebar-width: clamp(16rem, 20vw, calc(310px + 2rem ));  
    --map-info-panel-width: calc(310px + 2rem );
    --pages-content-width: clamp(100px, calc(100% - 4rem), 1400px);  
    /* default 16calc(100% - 4rem) */
}


:focus-visible {
    outline-color: var(--color-brown-dark);
}
::-moz-selection { /* Code for Firefox */
    color: var(--color-brown-light);
    background: var(--color-brown-dark);
}
  
::selection {
    color: var(--color-brown-light);
    background: var(--color-brown-dark);
}


@media screen and (max-width: 768px) {
    :root {
        --sidebar-width: 0rem;
        --header-height: 60px;
        --pages-content-width: clamp(100px, calc(100% - 2rem), 1400px);  
    }
}



