@import '../../assets/variables/variables.scss';

.toilet {
  @include custom-scrollbar();
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &__info {
    width: 100%;
    color: var(--color-brown-dark);

    h1 {
      padding-inline: 1rem;
      margin-top: 1rem;
      font-size: 1.7rem;
      font-weight: 700;
      text-wrap: wrap;
      line-height: 2.2rem;
    }

    &__address {
      padding-inline: 1rem;
      display: flex;
      flex-direction: row;
      font-weight: 600;
      font-size: 1rem;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.8rem;
      margin-top: 0.8rem;
      p {
        line-height: 1.3rem;
      }
    }

    &__tagsList {
      padding-inline: 1rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    &__reviews {
      border-top: 1px solid var(--color-brown-soft);
      padding-top: 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-inline: 1.5rem;
      h2 {
        font-weight: 600;
        color: var(--color-brown-dark);
      }
      a {
        color: var(--color-brown-dark);
       
        font-weight: 700;
        margin-left: 0.3rem;
        margin-right: auto;
      }
      a:hover{
        opacity: 0.6;
      }
      p {
        font-weight: 700;
        font-size: 1.6rem;
      }
    }
    &__btn {
      text-decoration: underline;
      cursor: pointer;
      color: var(--color-brown-medium);
    }
    &__stars {
      padding-inline: 1.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--color-brown-soft);
      margin-top: 1.4rem;
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-block: 1rem;
      margin-inline: 1rem;
      h2 {
        font-weight: 800;
        margin-left: 0.5rem;
      }
    }

    &__warning {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      margin-inline: 1rem;
      margin-right: 1rem;
      gap: 0.5rem;
      p {
        font-size: 1rem;
        font-weight: 800;
        color: var(--color-red);
      }

      margin-top: 0.5rem;
    }

    &__user {
      font-weight: 600;
      font-size: 1rem;
      margin-left: auto;
      color: var(--color-brown-dark);
      width: fit-content;
      padding-inline: 1rem;
      padding-bottom: 1rem;
      strong {
        cursor: pointer;
        font-weight: 800;

        text-decoration: underline;
      }
      strong:hover{
        opacity: 0.6;
      }

    }
  }

  &__reviewPage {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    min-height: calc(100% - var(--header-height));
    background-color: var(--color-brown-light);
    color: var(--color-brown-dark);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}


