.fiveStars{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.6rem;
    p{
        font-size: 1rem;
        font-weight: 600;
    }

    &__stars{
        display: flex;
        flex-direction: row;
        max-width: fit-content;
        gap: 0.1rem;
       
    }
}