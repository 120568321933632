@import '../../assets/variables/variables.scss';
.reviewPage{
    @include custom-scrollbar();
    width: 100%;
    height: 100%;
    overflow-y: auto;
    min-height: calc(100% - var(--header-height));
    box-sizing: border-box;
    background-color: var(--color-brown-light);
    color: var(--color-brown-dark);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 4;

    &__head{
        display: flex;
        flex-direction: row;
        align-items: center;
        &__title{
            text-align: right;
            margin-left: auto;
            font-size: 1rem;
            font-weight: 800;
            line-height: 1.2rem;
        }
    }   
}