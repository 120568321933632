.page{
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background-color: var(--color-background);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-inline: 2rem;
    
    &>*{
        max-width: 300px;
    }

    & > h1{
        font-size: 9rem;
        color: var(--color-brown-dark);
        margin-bottom: 1rem;
    }
    & >p{
      
        max-width: 800px;
        font-size: 1.4rem;
        font-weight: 700;
        color: var(--color-brown-medium);
        margin-bottom: 1rem;
    }
}

@media (max-width: 768px) {
    .page {
        h1 {
            font-size: 6rem;
        }
        p {
            font-size: 1.2rem;
        }
    }
}

@media (max-width: 480px) {
    .page {
        padding-inline: 1rem;
        h1 {
            font-size: 4rem;
        }
        p {
            font-size: 1rem;
        }
    }
}   