.button{
    border-radius: 100px;
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: all;
    z-index: 9;
}
.button:hover{
    transform: scale(1.06);
}
.button:active{
    transform: scale(0.96);
}