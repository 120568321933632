.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
  padding: 1rem;
  width: clamp(200px, 60%, 400px);

  h2 {
    margin-left: auto;
    font-size: 2.3rem;
    color: var(--color-brown-dark);
  } 
  p {
    margin-left: auto;
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-brown-dark);
  }

  path {
    stroke: var(--color-brown-dark);
  }
}

@media (max-width: 768px) {
  .info {
    flex-direction: row;
    align-items: flex-start;
    gap: 0.8rem;
    width: 100%;

    padding: 0.5rem;
    h2 {
      margin-left: 0;
      font-size: 1.8rem;
    }
    p {
      margin-left: 0;
      font-size: 0.9rem;
    }
  }
}
