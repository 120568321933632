.carousel{

    width: 100%;
    height: 280px;
    
    &__image{
        object-fit: cover;
        width: 100%;
        height: 280px;
        overflow: hidden;
    }
    &__img{
        object-fit: cover;
        width: 100%;
        height: 280px;
        overflow: hidden;
        cursor: pointer;
    }
    &__image:hover{
        cursor: grab;
    }
    &__image:active{
        cursor: grabbing;
    }
    
    ul{
        transform: translateY(-30px);   
        pointer-events: all; 
        filter: invert(1);
    }
    ul button{
        transform: scale(2.3);
        background: radial-gradient(ellipse ,rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 18%, rgba(199, 199, 199, 0.656) 18%, rgba(69,69,69,0) 28%);
    }
}   