.review{
    background-color: var(--color-white);
 
    height: fit-content;
    max-width: 100%;
    padding: 1rem;
    border-radius: 9px;

    &__head{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        &__info{
            display: flex;
            flex-direction: column;
            align-items: flex-start;

        }
        img{ 
            cursor: pointer;
            border-radius: 100px;
            width: 42px;
            height: 42px;
        }

        h2{
            cursor: pointer;
            font-size: 1rem;
            font-weight: 800;
            color: var(--color-brown-dark);
            line-height: 1.4rem;
        }
        p{
            color: var(--color-brown-soft);
            font-size: 0.9rem;
            font-weight: 800;
            line-height: 1.2rem;
        }
        &__star{
            
            margin-left: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        
            p{
                color: var(--color-brown-dark);
                font-size: 1.2rem;
            }
            
            
        }
    }

    &__text{
        margin-top: 1rem;
        font-size: 0.9rem;
        font-weight: 600;
        line-height: 1.2rem;
    }

    
}