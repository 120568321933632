.dropdown {
  position: fixed;
  z-index: 1;
  top: calc(var(--header-height) - 20px );
  right: calc( 80px);
  border-radius: 9px;
  border: 2px solid var(--color-brown-dark);
  background: var(--color-white);
  transition: all 0.3s;
  width: 260px;
  box-shadow:  0 0 100px 100000px rgba(0, 0, 0, 0.151);
  min-height: 200px;
  height: fit-content; 
  -webkit-user-select: none;      
	-moz-user-select: none; 
	-ms-user-select: none; 
	user-select: none; 

  //border-top: 50px solid #cf0d0d69;

  &__content{
    padding: 1rem;
    & > *{
      background-color: var(--color-background);
      color: var(--color-brown-dark);
      border-radius: 9px;
      min-height: 20px;
      width: 100%;
      cursor: pointer;
      padding: 0.5rem;
    }
    & > *:hover{
      opacity: 0.8;
    }
  }


  &__top{
    color: var(--color-brown-dark);
    display: flex;
    padding-block: 0.5rem;
    align-items: center;
    justify-content: center;
    // background-color: var(--color-brown-light);
    border-bottom: 2px solid var(--color-gray-light);
  }
  p{
    font-size: 1rem;
    font-weight: 600;
  }
  

}

.dropdown::before{
  content: '';
  position: absolute;
  padding-top: 20px;
  padding-left: 100px;
  box-sizing: border-box;
  top: -52px;
  right: 15px;
  border: 15px solid transparent;
  // border-bottom-color: var(--color-brown-dark);
  
}

.dropdown:after{
  content: '';
  position: absolute;
  padding-top: 20px;

  box-sizing: border-box;
  top: -52px;
  right: 15px;
  border: 15px solid transparent;
  border-bottom-color: var(--color-brown-dark);
}

    


