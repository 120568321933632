.tag{
    height: 26px;
    width: fit-content;
    border-radius: 9px;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    padding-left: 0.3rem;
    padding-right: 0.5rem;
    padding-block: 0.2rem;
    background-color: var(--color-white);

    &__text{
        color: var(--color-brown-dark);
        font-size: 0.9rem;
        font-weight: 700;
    }
}