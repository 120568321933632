.button{
    border-radius: 100px;
    height: 48px;
    min-height: 48px;
    width: 100%;

    //max-width: 310px;

    padding: 20px 20px;
    box-sizing: border-box;
    outline: none;
    border:  #ffffff solid 2px;
  
    font-size: 17px;
    font-weight: 800;
    font-family: "Manrope",sans-serif ,monospace;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--color-brown-dark);
    color: var(--color-brown-light);
    opacity: 1;
}

.button:hover{
    opacity: 0.95;
}
.button:active{
    opacity: 1;
}
